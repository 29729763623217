<template>
    <div class="card-table-header table-crm-smart custom__scroll" v-loading="loadingData" element-loading-spinner="el-icon-loading">
        <table 
            class="table-my-code table-bordered"
            :class="mode ? 'table__myday' : 'table__mynight'"
            >
            <thead>
                <tr>
                    <th class="w50p" v-if="columns.id.show">
                        {{ columns.id.title }}
                    </th>
                    <th class="w75p" v-if="columns.deal_id.show">
                        {{ columns.deal_id.title }}
                    </th>

                    <th v-if="columns.sender.show">
                        {{ columns.sender.title }}
                    </th>

                    <th v-if="columns.reciever.show">
                        {{ columns.reciever.title }}
                    </th>

                    <th v-if="columns.partner.show">
                        {{ columns.partner.title }}
                    </th>

                    <th v-if="columns.current_location.show">
                        {{ columns.current_location.title }}
                    </th>

                    <th v-if="columns.from_filial.show">
                        {{ columns.from_filial.title }}
                    </th>

                    <th v-if="columns.to_filial.show">
                        {{ columns.to_filial.title }}
                    </th>

                    <th v-if="columns.deal_product_id.show">
                        {{ columns.deal_product_id.title }}
                    </th>

                    <th v-if="columns.total_quantity.show">
                        {{ columns.total_quantity.title }}
                    </th>

                    <th v-if="columns.incoming_quantity.show">
                        {{ columns.incoming_quantity.title }}
                    </th>

                    <th v-if="columns.remainder.show">
                        {{ columns.remainder.title }}
                    </th>

                    <th v-if="columns.weight.show">
                        {{ columns.weight.title }}
                    </th>

                    <th v-if="columns.barcode.show">
                        {{ columns.barcode.title }}
                    </th>

                    <th v-if="columns.date.show">
                        {{ columns.date.title }}
                    </th>

                    <th v-if="columns.shipping_cost.show">
                        {{ columns.shipping_cost.title }}
                    </th>

                    <th v-if="columns.comment.show">
                        {{ columns.comment.title }}
                    </th>

                    <th v-if="columns.created_at.show">
                        {{ columns.created_at.title }}
                    </th>

                    <th v-if="columns.updated_at.show">
                        {{ columns.updated_at.title }}
                    </th>

                    <th v-if="columns.settings.show">
                        {{ columns.settings.title }}
                    </th>
                </tr>

                <tr class="filter_sorche">
                    <th v-if="columns.id.show">
                        <el-input
                            clearable
                            size="mini"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.deal_id"
                            :placeholder="columns.id.title"
                            class="id_input"
                        ></el-input>
                    </th>

                    <th v-if="columns.deal_id.show">
                        <crm-input
                            :placeholder="columns.deal_id.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.deal_id"
                            size="mini"
                        ></crm-input>
                    </th>

                    <th v-if="columns.sender.show">
                        <select-client
                            :size="'medium'"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.sender.title"
                            v-model="filterForm.client_id"
                        >
                        </select-client>
                    </th>

                    <th v-if="columns.reciever.show">
                        <select-client
                            :size="'medium'"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.reciever.title"
                            v-model="filterForm.to_client_id"
                        >
                        </select-client>
                    </th>

                    <th v-if="columns.partner.show">
                        <select-client
                            :size="'medium'"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.partner.title"
                            :query="{clientType_id: 1}"
                            v-model="filterForm.partner_client_id"
                        >
                        </select-client>
                    </th>

                    <th v-if="columns.current_location.show">
                    </th>

                    <th v-if="columns.from_filial.show">
                        <select-from-filial
                            :size="'medium'"
                            :select_only="false"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.from_filial.title"
                            v-model="filterForm.from_filial_id"
                        >
                        </select-from-filial>
                    </th>

                    <th v-if="columns.to_filial.show">
                        <select-to-filial
                            :size="'medium'"
                            :select_only="false"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.to_filial.title"
                            v-model="filterForm.to_filial_id"
                        >
                        </select-to-filial>
                    </th>

                    <th v-if="columns.deal_product_id.show">
                        <crm-input
                            :placeholder="columns.deal_product_id.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.product_name"
                        ></crm-input>
                    </th>

                    <th v-if="columns.total_quantity.show">
                        <crm-input
                            disabled
                            :placeholder="columns.total_quantity.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.total_quantity"
                        ></crm-input>
                    </th>

                    <th v-if="columns.incoming_quantity.show">
                        <crm-input
                            disabled
                            :placeholder="columns.incoming_quantity.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.incoming_quantity"
                        ></crm-input>
                    </th>

                    <th v-if="columns.remainder.show">
                        <crm-input
                            disabled
                            :placeholder="columns.remainder.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.remainder"
                        ></crm-input>
                    </th>

                    <th v-if="columns.weight.show">
                        <crm-input
                            disabled
                            :placeholder="columns.weight.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.weight"
                        ></crm-input>
                    </th>

                    <th v-if="columns.barcode.show">
                        <crm-input
                            :placeholder="columns.barcode.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.barcode"
                        ></crm-input>
                    </th>

                    <th v-if="columns.date.show">
                        <crm-date-picker
                            :placeholder="columns.date.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.date"
                        ></crm-date-picker>
                    </th>

                    <th v-if="columns.shipping_cost.show">
                        <crm-input
                            disabled
                            :placeholder="columns.shipping_cost.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.shipping_cost"
                        ></crm-input>
                    </th>

                    <th v-if="columns.comment.show">
                        <crm-input
                            disabled
                            :placeholder="columns.comment.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.comment"
                        ></crm-input>
                    </th>

                    <th v-if="columns.created_at.show">
                        <crm-date-picker
                            :placeholder="columns.created_at.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.created_at"
                        ></crm-date-picker>
                    </th>

                    <th v-if="columns.updated_at.show">
                        <crm-date-picker
                            :placeholder="columns.updated_at.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.updated_at"
                        ></crm-date-picker>
                    </th>

                    <th
                        class="settinW"
                        v-if="columns.settings.show"
                    ></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="productCirculation in list" :key="productCirculation.id" class="cursor-pointer">

                    <td v-if="columns.id.show">
                        {{ productCirculation.id }}
                    </td>

                    <td v-if="columns.deal_id.show">
                        {{ productCirculation.deal_id }}
                    </td>

                    <td v-if="columns.sender.show">
                        {{ productCirculation.client ? (productCirculation.client.custom_id ? ('ID: ' + productCirculation.client.custom_id) : '') : '' }} <br>
                        {{ productCirculation.client ? productCirculation.client.name : '' }} <br>
                    </td>

                    <td v-if="columns.reciever.show">
                        <span v-if="productCirculation.reciever">
                            {{ productCirculation.reciever ? (productCirculation.reciever.custom_id ? ('ID: ' + productCirculation.reciever.custom_id) : '') : '' }} <br>
                            {{ productCirculation.reciever ? productCirculation.reciever.name : '' }}<br>
                        </span>
                    </td>

                    <td v-if="columns.partner.show">
                        <span v-if="productCirculation.partner">
                            {{ productCirculation.partner ? (productCirculation.partner.custom_id ? ('ID: ' + productCirculation.partner.custom_id) : '') : '' }} <br>
                            {{ productCirculation.partner ? productCirculation.partner.name : '' }}<br>
                        </span>
                    </td>

                    <td v-if="columns.current_location.show">
                        {{ productCirculation.current_location }}
                    </td>

                    <td v-if="columns.from_filial.show">
                        {{ productCirculation.from_filial }}
                    </td>

                    <td v-if="columns.to_filial.show">
                        {{ productCirculation.to_filial }}
                    </td>

                    <td v-if="columns.deal_product_id.show">
                        {{ productCirculation.product ? productCirculation.product.name : ''}}
                    </td>

                    <td v-if="columns.total_quantity.show">
                        {{ productCirculation.total_quantity }}
                    </td>

                    <td v-if="columns.incoming_quantity.show">
                        {{ (form.id && form.id == productCirculation.id) ? '' : productCirculation.incoming_quantity }}
                        <el-input
                            :ref="'incomingReff'+productCirculation.id" 
                            v-if="form.id && form.id == productCirculation.id" 
                            placeholder="update incoming quantity" 
                            v-model="form.incoming_quantity" 
                            size="mini">
                        </el-input>
                    </td>

                    <td v-if="columns.remainder.show">
                        {{ productCirculation.remainder }}
                    </td>

                    <td v-if="columns.weight.show">
                        {{ productCirculation.weight }}
                    </td>

                    <td v-if="columns.barcode.show">
                        {{ productCirculation.barcode }}
                    </td>

                    <td v-if="columns.date.show">
                        {{ productCirculation.date }}
                    </td>

                    <td v-if="columns.shipping_cost.show">
                        {{ productCirculation.shipping_cost }}
                    </td>

                    <td v-if="columns.comment.show">
                        {{ productCirculation.comment }}
                    </td>
                
                    <td v-if="columns.created_at.show">
                        {{ productCirculation.created_at }}
                    </td>

                    <td v-if="columns.updated_at.show">
                        {{ productCirculation.updated_at }}
                    </td>

                    <td v-if="columns.settings.show" class="settings-td">
                        <div class="flight__edit">
                            <a class="ml-1" @click="editMethod(productCirculation)" v-if="permissions.some(per => per.slug == 'productCirculations.update')">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </a>
                            <a class="ml-1">
                                <el-dropdown @command="handleCommand">
                                    <span class="el-dropdown-link more_icons">
                                        <i class="fa fa-print"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" size="mini">      
                                        <el-dropdown-item :command="{action: 'barcode_with_address', model: productCirculation}" icon="fa fa-print">       
                                            {{ $t("message.barcode_with_address") }}
                                        </el-dropdown-item>
                                        <el-dropdown-item :command="{action: 'barcode_without_address', model: productCirculation}"  icon="fa fa-print">       
                                                {{ $t("message.barcode_without_address") }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </a>
                            <a class="ml-1" @click="updateParcelProducts(productCirculation)">
                                <i class="fa fa-eye"></i>
                            </a>
                            <a class="ml-1" @click="destroyMethod(productCirculation)" v-if="permissions.some(per => per.slug == 'productCirculations.delete')">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </transition-group>
        </table>
        <div class="my___pagination">
            <crm-pagination
                @c-change="updatePagination"
                :class="mode ? 'pagination__day' : 'pagination__night'"
                :pagination="pagination"
            ></crm-pagination>
            <!-- <Pagination /> -->
        </div>

        <el-drawer
            :with-header="false"
            :visible.sync="drawerUpdate"
            size="70%"
            ref="drawerUpdate"
            @opened="drawerOpened('drawerUpdateChild')"
            @closed="drawerClosed('drawerUpdateChild')"
        >
            <crm-update
                :selectedItem="selectedItem"
                ref="drawerUpdateChild"
                drawer="drawerUpdate"
            ></crm-update>
        </el-drawer> 

        <el-dialog 
            class="dialog__modal" 
            :title="$t('message.products')" 
            :visible.sync="dialogProductsList" 
            width="60%" 
            @opened="$refs['parcelProducts'] ? $refs['parcelProducts'].afterOpen() : ''"
            @closed="empty()">
            <parcel-products-list ref="parcelProducts" :selectedItem="selectedProd"></parcel-products-list>
        </el-dialog>

    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";
import CrmUpdate from "./crm-update-product";
import parcelProductsList from "@/views/waiting_product/components/parcel-products-list";

export default {
    name: "tasks",
    mixins: [list, form, drawer],
    components: {
        selectFromFilial,
        selectToFilial,
        selectClient,
        CrmUpdate,
        parcelProductsList
    },
    props: {
        parentFilter: {
            default: Object,
        },
    },
    data() {
        return {
            showHistory: false,
            loadingButton: false,
            activeTabName: 'products',
            form: {
                id: null,
                incoming_quantity: 0
            },
            dialogProductsList: false,
            selectedProd: {},
        };
    },
    watch: {
        parentFilter: {
            handler: async function(newVal, oldVal) {
                if(newVal && newVal.hasOwnProperty('from_filial_id')){
                    this.filterForm.from_filial_id = newVal.from_filial_id;
                }
                if(newVal && newVal.hasOwnProperty('to_filial_id')){
                    this.filterForm.to_filial_id = newVal.to_filial_id;
                }
            },
            deep: true,
            immediate: true
      },
    },
    created() {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
        this.debouncedFetchData = _.debounce(this.fetchData, 500);
    },
    computed: {
        ...mapGetters({
            list: "productCirculations/list",
            permissions: "auth/permissions",
            columns: "productCirculations/columns",
            pagination: "productCirculations/parcelPagination",            
            filter: "productCirculations/filter",
            sort: "productCirculations/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "productCirculations/index",
            setPagination: "productCirculations/setPagination",
            updateSort: "productCirculations/updateSort",
            updateFilter: "productCirculations/updateFilter",
            updateColumn: "productCirculations/updateColumn",
            updatePagination: "productCirculations/updateParcelPagination",
            show: "productCirculations/show",
            updateEditedRow: "productCirculations/updateEditedRow",
            update: "productCirculations/update",
            empty: "productCirculations/empty",
            delete: "productCirculations/destroy",
            refreshData: "productCirculations/refreshData",
            printProductBarcode: "productCirculations/printProductBarcode",
        }),

        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort, circulated: false, cargo_type: 'parcel'};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },

        updateParcelProducts(parcel) {
            this.selectedProd = {id: parcel.deal_product_id};
            this.dialogProductsList = true;
        },

        destroyMethod(product) {
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this') + ' ' + this.$t('message.all_incoming_products_will_be_deleted'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                this.destroy(product);
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
        },
        changeFilials() {
            if(this.filterForm.from_filial_id && this.filterForm.to_filial_id){
                let from = JSON.parse(JSON.stringify(this.filterForm.from_filial_id));
                let to = JSON.parse(JSON.stringify(this.filterForm.to_filial_id));
                this.$set(this.filterForm, 'from_filial_id', to);
                this.$set(this.filterForm, 'to_filial_id', from);
            }
        },

        showIncomingHistory(){
            this.showHistory = !this.showHistory;
            this.$set(this.filterForm, 'showHistory', this.showHistory)
        },

        printBarCode(payload) {
            this.printProductBarcode(payload)
            .then(res => {
                const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                WinPrint.document.write(res.data);
                WinPrint.document.close();
                WinPrint.focus();
                setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            })
            .catch(err => {
                this.$alert(err);
            });
        },
        updateIncoming(){
            this.loadingButton = true;
            this.update(this.form).then(res => {
                if(res.data.result.success){
                    this.updateEditedRow(this.form);
                    this.$alert(res);
                    this.cancelUpdate();
                }else{
                    this.cancelUpdate();
                    this.$error_message(res);
                }
                this.loadingButton = false;
            }).catch(err => {
                this.loadingButton = false;
            });
        },
        cancelUpdate(){
            this.$set(this.form, 'id', null);
            this.$set(this.form, 'incoming_quantity', 0);
        },
        editMethod(model){
            this.selectedItem = model;
            this.drawerUpdate = true;
        },
        handleCommand({action, model}) {
            let weight = 0;
            if(model.remainder > 0){
                weight = model.weight / model.remainder
            }
            if (action === "barcode_with_address") {
                this.printBarCode({barcode: model.barcode, deal_id: model.deal_id, date: model.date, weight: weight});
            }

            if (action === "barcode_without_address") {
                this.printBarCode({barcode: model.barcode, deal_id: model.deal_id, date: model.date, weight: weight, type: 'without_address'});
            }

            if (action === "return") {
            }
        }, 
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('productCirculations/EMPTY_LIST');
        next()
    },
};
</script>

<style>
.coloa3 {
   background: #ff3f3f;
}
.container__tasks {
    width: 100% ;
    margin: 0 !important;
}
.test__width {
    width: 100% !important;
}
.button_no_padding {
    padding: 7px 10px !important;
}
</style>
