<template>
    <el-dialog :append-to-body="true" :title="$t('message.emails')" :visible.sync="dialogEmail">
      <div>
        <el-form :model="form" :rules="rules" ref="form" label-position="top">
            <el-form-item :label="$t('message.sender')" prop="client_id">
                <select-client
                    :size="'large'"
                    class="w-100"
                    :placeholder="$t('message.sender')"
                    :id="form.client_id"
                    v-model="form.client_id"
                    @getClient="getClient"
                >
                </select-client>
            </el-form-item>
            <span class="mt-1 mb-5 font-bold"> 
                Email:  {{ form.email }} 
            </span>
            <el-form-item :label="$t('message.comment')" prop="comment">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    :placeholder="$t('message.comment')"
                    v-model="form.comment">
                </el-input>
            </el-form-item>
        </el-form>
      </div>
      <div class="text-right mt-5">
        <el-button :loading="loadingData" class="w-100" @click="sendEmail" type="primary"> {{ $t('message.send') }} </el-button>
      </div>
    </el-dialog>
</template>
  
<script>
  // @ is an alias to /src
  import { mapGetters, mapActions } from "vuex";
  import selectClient from "@/components/inventory/select-client";
  
  export default {
    name: "updatePackProdsWeight",
    components: {
        selectClient,
    },
    props: {
        track_code: {
            default: String
        },
    },
    data() {
      return {
        dialogEmail: false,
        loadingData: false,
        form: {
            track_code: '',
            comment: '',
        },
      };
    },

    watch: {
        track_code: {
            handler: async function(newVal, oldVal) {
                if(newVal){
                  this.$set(this.form, 'track_code', JSON.parse(JSON.stringify(newVal)));
                  this.dialogEmail = true;
                }
            },
            deep: true,
            immediate: true
        },
    },
  
    computed: {
      ...mapGetters({
        mode: "MODE",
        rules: "trackEmail/rules",
      }),
    },

    methods: {
      ...mapActions({
        submit: "trackEmail/store",
      }),
      getClient(client) {
        this.form.email = JSON.parse(JSON.stringify(client.email));
      },
      sendEmail() {
        this.$refs["form"].validate((valid) => {
            if (valid) {
                this.loadingData = true;
                this.dialogEmail = false;
                this.submit(this.form)
                    .then(res => {
                        this.$alert(res);
                        this.loadingData = false;
                    })
                    .catch(err => {
                        this.$alert(err);
                        this.loadingData = false;
                    });

            }else {
                this.loadingData = false;
            }
        });
      }
    },
  };
</script>
<style>
  .mm_active{
      color: #E6A23C;
  }
  .mm_process{
      color: #409EFF;
  }
  .mm_success{
      color: #67C23A;
  }
  .link{
      text-decoration: none;
  }
</style>
  
  